import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@app/shared/interfaces/abstract/query.params';
import { IClientIssuer } from '@shared-global/interfaces/clients-issuers/interfaces/client-issuer.interface';
import { IFindClientsIssuersByCriteriaRequest } from '@shared-global/interfaces/clients-issuers/interfaces/find-clients-issuers-by-criteria-request.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

export type ClientIssuerRequests = IFindClientsIssuersByCriteriaRequest &
  QueryParamsInterface;

@Injectable({
  providedIn: 'root',
})
export class ClientIssuerService extends AbstractCrudService<
  IClientIssuer,
  ClientIssuerRequests
> {
  protected override feature = 'clients-issuers';
  protected override endPoint = `/clients-issuers/`;
}
